<template>
<p>Register for event here</p>
<button @click="register">Register Me</button>
</template>

<script>
export default {

  props: ['event'],
    methods: {
        register() {
            //call to api
            //if registered then redirect to event details
            this.$router.push({
                name: 'EventDetails',
            })
        }
    }
}
</script>

